import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import SockJS from "sockjs-client";
import Stomp from "stomp-websocket";
import { driverRestUrl, passengerRestUrl } from './common/restUrl';
import { sessionActions } from './store/session';

const SocketController = () => {
    const dispatch = useDispatch();

    const [response, setResponse] = useState();
    const [stompClient, setStompClient] = useState(undefined);

    const [authenticated, isDriver] = useSelector((state) => {
        return [!!state.session.user, state.session.user?.type === 'DRIVER']
    });

    useEffect(() => {
        if (!stompClient && authenticated) {
            connectSocket();
        }
    }, [stompClient, authenticated])

    // #TODO: fix A non-serializable value was detected in an action
    const onError = error => {
        setTimeout(() => setStompClient(undefined), 3000);
    }

    const connectSocket = () => {
        console.log('connecting to socket...');
        var socket = new SockJS('/kaxi');
        var sc = Stomp.over(socket);
        sc.debug = null;
        var url = isDriver ? driverRestUrl.socketConnect : passengerRestUrl.socketConnect;
        sc.heartbeat.outgoing = 10000;
        sc.heartbeat.incoming = 10000;
        sc.connect({}, function (frame) {
            dispatch(sessionActions.updateSocket(sc))
            console.log('socket connected');
            sc.subscribe(url, function (response) {
                if (response) {
                    dispatch(sessionActions.updateResponse(JSON.parse(response.body)))
                }
                setResponse(response.body);
            });
        }, onError);

        sc.ws.onclose = (event) => {
            console.log('socket disconnected');
            setTimeout(() => setStompClient(undefined), 3000);
        }

        setStompClient(sc)
    };


    return (
        <>
        </>
    );
};

export default connect()(SocketController);