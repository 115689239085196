export const HttpClient = {
    Post,
    Get,
    Put,
    Delete
}

async function Post(url, body) {
    const request = {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: body
    }
    const response = await fetch(url, request);
    return handleResponse(response)
}

async function Put(url, body) {
    const request = {
        method: "PUT",
        headers: { 'Content-Type': 'application/json' },
        body: body
    }
    const response = await fetch(url, request);
    return handleResponse(response)
}

async function Get(url) {
    const request = {
        method: "GET",
        headers: { 'Content-Type': 'application/json' }
    }
    const response = await fetch(url, request);
    return handleResponse(response);
}

async function Delete(url) {
    const request = {
        method: "DELETE"
    }
    const response = await fetch(url, request);
    return handleResponse(response);
}

const handleResponse = response => {
    return new Promise((resolve, reject) => {
        if (response.ok) {
            response.json()
                .then(json => resolve(json))
                .catch(_ => resolve());

        }
        else {
            response.text().then(errorMsg => {
                if (!errorMsg)
                    errorMsg = 'خطایی رخ داده است';
                reject(errorMsg);
            });
        }
    });
}