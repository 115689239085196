import AccountBoxIcon from '@mui/icons-material/AccountBox';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ShareIcon from '@mui/icons-material/Share';
import { Divider, Grid, IconButton, List, ListItem, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RWebShare } from 'react-web-share';
import { HttpClient } from '../../common/HttpClient';
import { driverRestUrl, tripRestUrl, userRestUrl } from '../../common/restUrl';
import { formatRequestStatus, formatStop } from '../../common/util/formatter';
import { sessionActions } from '../../store';
import Rating from '../Rating/Rating';

const parts = [
    {
        key: 'userPanel',
        display: "اطلاعات کاربری",
        icon: <AccountBoxIcon />
    },
    {
        key: 'trips',
        display: "سفرها",
        icon: <DirectionsCarIcon />
    },
    {
        key: 'exit',
        display: 'خروج از حساب کاربری',
        icon: <ExitToAppIcon />
    }
]

const useStyles = makeStyles(theme => ({
    marginBottom: {
        marginTop: '10px !important'
    },
    tripHistoryCard: {
        borderRadius: 6,
        boxShadow: 'rgb(0 0 0 / 10%) 0px 2px 12px 0px',
        border: '1px solid rgb(230, 230, 230)',
        marginBottom: 16,
        color: 'rgb(64, 64, 64)',
        justifyContent: 'flex-start',
        padding: 10
    },
    tripHistoryDate: {
        color: 'rgb(34, 170, 88)'
    },
    feedbackStarsContainer: {
        textAlign: 'left',
        paddingLeft: 10,
        alignSelf: 'flex-end',
        [theme.breakpoints.down('md')]: {
            flex: '0 0 100%',
            paddingLeft: 5,
            order: 2
        },
    },
    feedbackButton: {
        position: 'relative',
    },
    mobileNumber: {
        direction: 'rtl',
        '& .MuiInputBase-input': {
            textAlign: 'left'
        }
    },
    shareButtonContainer: {
        textAlign: 'right'
    }
}));


export default function DriverMenu() {
    const dispatch = useDispatch();
    const [selectedItem, setSelectedItem] = useState(null);
    const [tripsHistory, setTripsHistory] = useState([]);
    const [page, setPage] = useState({ currentPageNumber: 1, pageCount: null })
    const [user, setUser] = useState({
        firstName: 'در حال بارگزاری',
        lastName: 'در حال بارگزاری',
        nationalCode: 'در حال بارگزاری',
        mobileNumber: 'در حال بارگزاری'
    })
    const classes = useStyles();

    const getHistory = (page) => {
        const url = tripRestUrl.getHistory.replace('{page}', page)
        HttpClient.Get(url).then(response => {
            var tempArr = [...tripsHistory];
            tempArr.push(...response.finishedRequestList)
            setTripsHistory(tempArr);
            setPage({ currentPageNumber: response.currentPageNumber, pageCount: response.pageCount })
        }).catch(error => toast.error(error))
    }

    const getProfile = () => {
        HttpClient.Get(driverRestUrl.profile).then(response => {
            setUser(response)
        }).catch(error => toast.error(error))
    }

    useEffect(() => {
        if (selectedItem === "userPanel")
            getProfile()
        else if (selectedItem === "trips") {
            getHistory(1)
        }
    }, [selectedItem])


    const handleLogout = () => {
        HttpClient.Delete(userRestUrl.session);
        dispatch(sessionActions.updateUser(null));
        dispatch({ type: 'RESET_APP' });
    }

    const menuComopnent = () => {
        return (
            <List>
                {parts.map(part =>
                    <>
                        <ListItem
                            button
                            key={part.key}
                            onClick={
                                part.key === "exit" ? handleLogout
                                    : () => setSelectedItem(part.key)
                            }
                        >
                            {part.icon}
                            <span style={{ marginRight: 10 }}>{part.display}</span>
                        </ListItem>
                        <Divider />
                    </>
                )}
            </List>
        )
    }

    const userPanelComponent = () => {
        return (
            <div style={{ padding: 15 }}>
                <h3>اطلاعات کاربری</h3>
                <TextField
                    fullWidth
                    disabled
                    variant='outlined'
                    value={user.firstName}
                    label={"نام"}
                    className={classes.marginBottom}
                />
                <TextField
                    fullWidth
                    disabled
                    variant='outlined'
                    value={user.lastName}
                    label={"نام خانوادگی"}
                    className={classes.marginBottom}
                />
                <TextField
                    fullWidth
                    disabled
                    variant='outlined'
                    value={user.nationalCode}
                    label={"کد ملی"}
                    className={classes.marginBottom}
                />
                <TextField
                    fullWidth
                    disabled
                    variant='outlined'
                    value={user.mobileNumber}
                    label={"شماره موبایل"}
                    className={`${classes.marginBottom} ${classes.mobileNumber}`}
                />
            </div>
        )
    }

    const getTripReceiptText = (tripHistory) => {
        var text = "";
        text = "زمان: "
    }

    const handleScrollEnd = (event) => {
        if (page.currentPageNumber === page.pageCount)
            return;
        if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight - 5) {
            getHistory(page.currentPageNumber + 1)
        }
    }

    const tripsHistoryComponent = () => {
        return (
            <div style={{ padding: 15, overflow: 'auto' }} onScroll={handleScrollEnd}>
                <h3>سفرها</h3>
                {
                    tripsHistory.map(tripHistory =>
                        <Grid container justifyContent={'space-between'} className={classes.tripHistoryCard}>
                            <Grid container>
                                <Grid item xs={6} sm={6}>
                                    <h4 className={classes.tripHistoryDate}>{new Date(tripHistory.requestTime).toLocaleString('fa-IR')}</h4>
                                </Grid>
                                <Grid item xs={12} sm={4} className={classes.feedbackStarsContainer}>
                                    <Rating
                                        value={tripHistory.feedback}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={6} sm={2} className={classes.shareButtonContainer}>
                                    <RWebShare
                                        data={{
                                            text: "",
                                            url: getTripReceiptText(tripHistory),
                                            title: 'اشتراک گذاری'
                                        }}

                                        closeText={'بستن'}
                                    >
                                        <IconButton onClick={() => { }} >
                                            <ShareIcon
                                                fontSize='medium'
                                                cursor={'pointer'}
                                                color={'info'}
                                            />
                                        </IconButton>
                                    </RWebShare>

                                </Grid>
                            </Grid>
                            <Grid style={{ paddingRight: 10 }} item xs={12}>
                                <h5>{`مبدا: ${tripHistory.startPointAddress}`}</h5>
                            </Grid>
                            <Grid style={{ paddingRight: 10 }} item xs={12}>
                                <h5>{`مقصدها:`}</h5>
                                <ul>
                                    {
                                        tripHistory.destinationsAddress.map((address, index) =>
                                            <li key={index}><h5>{address}</h5></li>
                                        )
                                    }
                                </ul>
                            </Grid>
                            <Grid style={{ paddingRight: 10 }} item xs={6}>
                                <h5>{`مسافر: ${tripHistory.passengerName + " " + tripHistory.passengerFamily}`}</h5>
                            </Grid>
                            <Grid style={{ paddingRight: 10 }} item xs={6}>
                                <h5>{`هزینه: ${tripHistory.price.toLocaleString('fa-IR')} ریال`}</h5>
                            </Grid>
                            <Grid style={{ paddingRight: 10 }} item xs={6}>
                                <h5>{`توقف: ${formatStop(tripHistory.stop)}`}</h5>
                            </Grid>
                            <Grid style={{ paddingRight: 10 }} item xs={6}>
                                <h5>{`وضعیت: ${formatRequestStatus(tripHistory.status)}`}</h5>
                            </Grid>
                        </Grid>
                    )
                }

            </div>
        )
    }

    const getComponent = () => {
        switch (selectedItem) {
            case null:
                return menuComopnent();
            case "userPanel":
                return userPanelComponent();
            case "trips":
                return tripsHistoryComponent();
        }
    }

    return (
        getComponent()
    )
}