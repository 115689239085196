import { useMediaQuery } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { useCallback, useEffect, useState } from "react";
import MapView from "../core/MapView";
import MapGeocoder from "../geocoder/MapGeocoder";
import MapDefaultCamera from "../main/MapDefaultCamera";
import MapCurrentLocation from "../MapCurrentLocation";
import MapPadding from "../MapPadding";
import MapPositions from "../MapPositions";

export default function PassengerMap({ positions }) {
    const theme = useTheme();
    const desktop = useMediaQuery(theme.breakpoints.up('md'));
    const [currentLocation, setCurrentLocation] = useState();
    const onClick = useCallback((_, category) => {
        // dispatch(devicesActions.select(deviceId));
    }, []);

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    setCurrentLocation({
                        lat: latitude,
                        lon: longitude
                    });
                },
                (error) => {
                    console.error('Error getting user location:', error);
                }
            );
        } else {
            console.error('Geolocation is not supported by this browser.');
        }
    }, []);

    return (
        <>
            <MapView>
                <MapDefaultCamera defaultPosition={currentLocation} defaultZoom={20} />
                <MapPositions positions={positions} onClick={onClick} />
            </MapView>
            <MapCurrentLocation />
            <MapGeocoder />
            {desktop && <MapPadding left={parseInt(0, 0)} />}
        </>
    )
}