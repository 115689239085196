import { Button, CircularProgress, Grid, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import landingPageBackground from "../../assets/landing-page-background.jpg";
import { HttpClient } from '../../common/HttpClient';
import { driverRestUrl, passengerRestUrl } from '../../common/restUrl';
import { sessionActions } from '../../store';

const useStyles = makeStyles({
    root: {
        textAlign: 'center',
        justifyContent: 'center'
    },
    img: {
        height: 350,
        width: 350
    },
    title: {
        marginBottom: "20px !important"
    },
    marginBottom: {
        marginBottom: "10px !important"
    }
});

export default function Login({ isDriver }) {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const classes = useStyles();

    const [step, setStep] = useState('login');
    const [inputsValues, setInputValues] = useState({ mobileNumber: '', totpCode: '' });
    const [isLoading, setIsLoading] = useState(false);

    const handleLogin = () => {
        setIsLoading(true);
        var url = isDriver ? (step === 'login' ? driverRestUrl.login : driverRestUrl.verify) : (step === 'login' ? passengerRestUrl.login : passengerRestUrl.verify)
        let body = { "mobileNumber": inputsValues.mobileNumber };
        if (step === "smsValidation")
            body.code = inputsValues.totpCode;
        if (!isDriver)
            body.register = false;
        HttpClient.Post(url, JSON.stringify(body))
            .then(response => {
                if (step === 'login') {
                    setStep('smsValidation')
                    setInputValues({ ...inputsValues, totpCode: serverTotpCode })
                } else {
                    var attributes = response.attributes;
                    delete response.attributes;
                    dispatch(sessionActions.updateUser(response))
                    if (attributes) {
                        var type = attributes.state;
                        delete attributes.state;
                        dispatch(sessionActions.updateResponse({ type: type, message: attributes }));
                    }
                    navigate("/home", { replace: true })
                }
                setIsLoading(false);
            }).catch(error => {
                setIsLoading(false);
                toast.error(error);
            })
    }

    const handleTextChange = text => {
        if (step === "login")
            setInputValues({ ...inputsValues, mobileNumber: text })
        else
            setInputValues({ ...inputsValues, totpCode: text })
    }

    return (
        <Grid container className={classes.root}>
            <Grid item xs={12} >
                <img src={landingPageBackground} className={classes.img} />
            </Grid>
            <Grid item xs={12} className={classes.title}>
                <Typography variant='h5'> {isDriver ? "ورود راننده" : "ورود مسافر"}</Typography>
            </Grid>
            <Grid item xs={8} className={classes.marginBottom}>
                <TextField
                    fullWidth
                    variant='outlined'
                    value={step === "login" ? inputsValues.mobileNumber : inputsValues.totpCode}
                    label={step === "login" ? "شماره موبایل خود را وارد کنید" : "کد تایید پیامک شده را وارد کنید"}
                    onChange={event => handleTextChange(event.target.value)}
                    type={step === "login" ? "tel" : "number"}
                />
            </Grid>
            <Grid item xs={8} className={classes.marginBottom}>
                <Button
                    fullWidth
                    variant='contained'
                    color='info'
                    onClick={handleLogin}
                >
                    {
                        !isLoading ?
                            step === "login" ? "ورود" : "تایید"
                            :
                            <CircularProgress size={25} />
                    }
                </Button>
            </Grid>
            <Grid item xs={8} className={classes.marginBottom}>
                <Button
                    fullWidth
                    variant='contained'
                    color='info'
                    onClick={() => { navigate(-1) }}
                >
                    بازگشت
                </Button>
            </Grid>
            {
                !isDriver && (
                    <Grid item xs={8} className={classes.marginBottom}>
                        <Button fullWidth variant='text' onClick={() => navigate("/passenger/register")}>ثبت نام</Button>
                    </Grid>
                )
            }
        </Grid>
    )
}