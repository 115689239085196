export const formatDistance = (value) => {
  if (value > 1) {
    return convertToPersianNumber(value.toFixed(1).toString()) + " کیلومتر";
  } else {
    return convertToPersianNumber((value * 1000).toString()) + " متر";
  }

}

export const formatTime = (value) => {
  let hours = Math.floor((value / (60 * 60)).toFixed(1));
  let minutes = Math.floor(((value / 60).toFixed(1)) % 60);
  let result = "";
  if (hours > 0)
    result = `${hours} ساعت`;
  if (minutes > 0) {
    if (!result)
      result = `${minutes} دقیقه`;
    else
      result += ` و ${minutes} دقیقه`;
  }
  if (!result)
    result = `${value} ثانیه`;
  return result;
}

export const formatPrice = (value) => {
  if (!value || isNaN(value))
    return '';
  let temp = Intl.NumberFormat('fa-IR').format(Number(value).toFixed())
  return `${convertToPersianNumber(temp)} ریال`;
}

export const convertToPersianNumber = (text) => {
  if (!text)
    return '';
  if (Number.isNaN(text))
    throw 'پارامتر ورودی باید عدد باشد'
  else {
    let normalizedNumber = "";
    for (const char of text) {
      switch (char) {
        case '1':
          normalizedNumber += '۱';
          break;
        case '2':
          normalizedNumber += "۲";
          break;
        case '3':
          normalizedNumber += "۳";
          break;
        case '4':
          normalizedNumber += "۴";
          break;
        case '5':
          normalizedNumber += "۵";
          break;
        case '6':
          normalizedNumber += "۶";
          break;
        case '7':
          normalizedNumber += "۷";
          break;
        case '8':
          normalizedNumber += "۸";
          break;
        case '9':
          normalizedNumber += "۹";
          break;
        case '0':
          normalizedNumber += "۰";
          break;
        default:
          normalizedNumber += char;
          break;
      }
    }
    return normalizedNumber;
  }
}

export const getPointName = (pointType) => {
  switch (pointType) {
    case 'source':
      return 'مبدا';
    case 'destination':
      return 'مقصد';
    default:
      return '';
  }
};

export const getPointColor = (pointType) => {
  switch (pointType) {
    case 'source':
      return '#2196F3';
    case 'destination':
      return '#4CAF50';
    case 'person':
      return '#f22eff';
    case 'car':
      return '#000000';
    case 'driver':
      return '#5252ff';
    default:
      return '#666666';
  }
};

export const formatStop = (stopRange) => {
  switch (stopRange) {
    case "NO_STOP":
      return "بدون توقف";
    case "RANGE5_10":
      return "5-10 دقیقه";
    case "RANGE10_15":
      return "10-15 دقیقه";
    case "RANGE15_20":
      return "15-20 دقیقه";
    default:
      return "";
  }
}

export const formatRequestStatus = (status) => {
  switch (status) {
    case "CANCEL_BY_DRIVER_IN_ACCEPT":
    case "CANCEL_BY_DRIVER_IN_ARRIVE":
      return "لغو شده توسط راننده";
    case "CANCEL_BY_PASSENGER_IN_START":
    case "CANCEL_BY_PASSENGER_IN_ACCEPT_BY_DRIVER":
    case "CANCEL_BY_PASSENGER_IN_ARRIVE_DRIVER":
      return "لغو شده توسط مسافر";
    case "CANCEL_BY_TIMER_IN_START":
      return "منتقضی شده";
    case "FINISH":
      return "اتمام سفر";
    default:
      return "نامعلوم";
  }
}