import { createSlice } from '@reduxjs/toolkit';

const { reducer, actions } = createSlice({
  name: 'session',
  initialState: {
    user: null,
    socket: null,
    response: { type: null, message: null }
  },
  reducers: {
    updateUser(state, action) {
      state.user = action.payload;
    },
    updateSocket(state, action) {
      state.socket = action.payload;
    },
    updateResponse(state, action) {
      state.response = action.payload;
    },
  },
});

export { actions as sessionActions };
export { reducer as sessionReducer };
