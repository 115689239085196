import { useMediaQuery } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { useCallback } from "react";
import MapView from "../core/MapView";
import MapGeocoder from "../geocoder/MapGeocoder";
import MapDefaultCamera from "../main/MapDefaultCamera";
import MapCurrentLocation from "../MapCurrentLocation";
import MapPadding from "../MapPadding";
import MapPositions from "../MapPositions";
import MapRoutePadding from "../MapRoutePadding";
import MapRoutePath from "../MapRoutePath";

export default function DriverMap({ positions, defaultPosition, route, showEntireRoute }) {
    const theme = useTheme();
    const desktop = useMediaQuery(theme.breakpoints.up('md'));

    const onClick = useCallback((_, category) => {
        // dispatch(devicesActions.select(deviceId));
    }, []);

    return (
        <>
            <MapView>
                <MapDefaultCamera defaultPosition={defaultPosition} />
                <MapPositions positions={positions} onClick={onClick} />
                {/* <MapRoutePath positions={route} /> */}
                {/* <MapRoutePadding positions={route} showEntireRoute={showEntireRoute} /> */}
            </MapView>
            <MapCurrentLocation />
            <MapGeocoder />
            {desktop && <MapPadding left={parseInt(0, 0)} />}
        </>
    )
}