import { jssPreset, StylesProvider } from '@mui/styles';
import { create } from 'jss';
import rtl from 'jss-rtl';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { prefixer } from 'stylis';
import rtlPlugin from 'stylis-plugin-rtl';

const cacheRtl = createCache({
    key: 'muirtl',
    stylisPlugins: [prefixer, rtlPlugin],
});

const jss = create({
    plugins: [...jssPreset().plugins, rtl()],
});


export default function Rtl({ ...props }) {
    return (
        <CacheProvider value={cacheRtl}>
            <StylesProvider jss={jss}>
                {props.children}
            </StylesProvider>
        </CacheProvider>
    );
}