import { Rating as MuiRating } from "@mui/material";
import { makeStyles } from "@mui/styles";

const labels = [
    'بدون نظر',
    'خیلی بد',
    'بد',
    'معمولی',
    'خوب',
    'عالی'
]

const useStyle = makeStyles({
    title: {
        marginBottom: 0,
    },
    stars: {
        marginRight: 10
    },
    description: {
        position: 'relative',
        top: -5,
        fontSize: '.8rem'
    }
})

export default function Rating({ title, defaultValue, value, handleChange, disabled }) {
    const classes = useStyle();

    return (
        <>
            {title && (
                <h5 className={classes.title}>{title}</h5>
            )}
            <MuiRating
                defaultValue={defaultValue}
                value={value}
                onChange={event => handleChange(event.target.value)}
                className={classes.stars}
                disabled={disabled}
            />
            <span className={classes.description}>{value ? labels[value] : labels[0]}</span>
        </>

    )
}