import AccountBoxIcon from '@mui/icons-material/AccountBox';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { Button, Divider, Grid, List, ListItem, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from "react-toastify";
import { HttpClient } from '../../common/HttpClient';
import { passengerRestUrl, tripRestUrl, userRestUrl } from '../../common/restUrl';
import { formatRequestStatus, formatStop } from '../../common/util/formatter';
import { sessionActions } from '../../store';
import RadioGroup from '../Radio/RadioGroup';
import Rating from '../Rating/Rating';

const parts = [
    {
        key: 'userPanel',
        display: "اطلاعات کاربری",
        icon: <AccountBoxIcon />
    },
    {
        key: 'trips',
        display: "سفرها",
        icon: <DirectionsCarIcon />
    },
    {
        key: 'exit',
        display: 'خروج از حساب کاربری',
        icon: <ExitToAppIcon />
    }
]

const useStyles = makeStyles({
    marginBottom: {
        marginTop: '10px !important'
    },
    tripHistoryCard: {
        borderRadius: 6,
        boxShadow: 'rgb(0 0 0 / 10%) 0px 2px 12px 0px',
        border: '1px solid rgb(230, 230, 230)',
        marginBottom: 16,
        color: 'rgb(64, 64, 64)',
        padding: 10
    },
    tripHistoryDate: {
        color: 'rgb(34, 170, 88)'
    },
    feedbackStarsContainer: {
        textAlign: 'left',
        paddingLeft: 10,
        alignSelf: 'flex-end'
    },
    feedbackButton: {
        position: 'relative',
    },
    mobileNumber: {
        direction: 'rtl',
        '& .MuiInputBase-input': {
            textAlign: 'left'
        }
    }
});

export default function PassengerMenu() {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [selectedItem, setSelectedItem] = useState(null);
    const [user, setUser] = useState({
        firstName: 'در حال بارگزاری',
        lastName: 'در حال بارگزاری',
        nationalCode: 'در حال بارگزاری',
        mobileNumber: 'در حال بارگزاری',
        email: 'در حال بارگزاری',
        gender: 0
    })
    const [tripsHistory, setTripsHistory] = useState([]);
    const [feedbacks, setFeedbacks] = useState([]);
    const [page, setPage] = useState({ currentPageNumber: 1, pageCount: null })

    useEffect(() => {
        if (selectedItem === "userPanel")
            getProfile();
        else if (selectedItem === "trips")
            getHistory(1);
    }, [selectedItem])

    const handleLogout = () => {
        HttpClient.Delete(userRestUrl.session);
        dispatch(sessionActions.updateUser(null));
        dispatch({ type: 'RESET_APP' });
    }

    const getProfile = () => {
        HttpClient.Get(passengerRestUrl.profile).then(response => {
            setUser({ ...response, gender: response.gender === "MALE" ? 1 : 0 })
        }).catch(error => toast.error(error))
    }

    const handleEdit = () => {
        const body = JSON.stringify({
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email
        });
        HttpClient.Put(passengerRestUrl.profile, body).then(response => {
            toast.info('اطلاعات با موفقیت ویرایش شد')
        }).catch(error => toast.error(error))
    }

    const handleFeedbackClick = (requestId, feedback) => {
        if (feedback != 0) {
            var body = JSON.stringify({ requestId: requestId, feedback: Number(feedback) });
            HttpClient.Put(tripRestUrl.feedback, body).then(_ => {
                var temp = [...tripsHistory];
                temp.forEach((item, index) => {
                    if (item.requestId === requestId) {
                        var newObj = { ...temp[index] }
                        newObj.feedback = feedbacks[index];
                        temp[index] = newObj;
                    }
                })
                setTripsHistory(temp)
                toast.info('بازخورد شما با موفقیت ثبت شد')
            }).catch(error => toast.error(error))
        }
    }

    const menuComopnent = () => {
        return (
            <List>
                {parts.map(part =>
                    <>
                        <ListItem
                            button
                            key={part.key}
                            onClick={
                                part.key === "exit" ? handleLogout
                                    : () => setSelectedItem(part.key)
                            }
                        >
                            {part.icon}
                            <span style={{ marginRight: 10 }}>{part.display}</span>
                        </ListItem>
                        <Divider />
                    </>
                )}
            </List>
        )
    }

    const userPanelComponent = () => {
        return (
            <div style={{ padding: 15 }}>
                <h3>اطلاعات کاربری</h3>
                <TextField
                    fullWidth
                    variant='outlined'
                    value={user.firstName}
                    label={"نام"}
                    className={classes.marginBottom}
                    onChange={event => setUser({ ...user, firstName: event.target.value })}
                />
                <TextField
                    fullWidth
                    variant='outlined'
                    value={user.lastName}
                    label={"نام خانوادگی"}
                    className={classes.marginBottom}
                    onChange={event => setUser({ ...user, lastName: event.target.value })}
                />
                <TextField
                    fullWidth
                    disabled
                    variant='outlined'
                    value={user.nationalCode}
                    label={"کد ملی"}
                    className={classes.marginBottom}
                />
                <TextField
                    fullWidth
                    disabled
                    variant='outlined'
                    value={user.mobileNumber}
                    label={"شماره موبایل"}
                    className={`${classes.marginBottom} ${classes.mobileNumber}`}
                />
                <RadioGroup
                    title={'جنسیت'}
                    options={[{ label: 'مرد', value: 1 }, { label: 'زن', value: 0 }]}
                    value={user.gender}
                    size={'small'}
                    disabled
                />
                <Button
                    sx={{ display: 'block', marginTop: 2 }}
                    onClick={handleEdit}
                    variant='contained'
                    color='primary'
                    fullWidth
                >
                    ویرایش اطلاعات
                </Button>
            </div>
        )
    }

    const getHistory = (page) => {
        const url = tripRestUrl.getHistory.replace('{page}', page)
        HttpClient.Get(url).then(response => {
            setFeedbacks(
                Array(tripsHistory.length + response.finishedRequestList.length).fill(0)
            )
            var tempArr = [...tripsHistory];
            tempArr.push(...response.finishedRequestList)
            setTripsHistory(tempArr);
            setPage({ currentPageNumber: response.currentPageNumber, pageCount: response.pageCount })
        }).catch(error => toast.error(error))
    }

    const handleScrollEnd = (event) => {
        if (page.currentPageNumber === page.pageCount)
            return;
        if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight - 5) {
            getHistory(page.currentPageNumber + 1)
        }
    }

    const tripsHistoryComponent = () => {
        return (

            <div style={{ padding: 15, overflow: 'auto' }} onScroll={handleScrollEnd}>
                <h3>سفرها</h3>
                {
                    tripsHistory.map((tripHistory, index) =>
                        <Grid container justifyContent={'space-between'} className={classes.tripHistoryCard}>
                            <Grid item xs={6}>
                                <h4 className={classes.tripHistoryDate}>{new Date(tripHistory.requestTime).toLocaleString('fa-IR')}</h4>
                            </Grid>
                            {
                                tripHistory.status === "FINISH" && (
                                    <Grid container xs={6}>
                                        <Grid item xs={12} sm={4} className={classes.feedbackStarsContainer}>
                                            <Rating
                                                value={tripHistory.feedback ? tripHistory.feedback : feedbacks[index]}
                                                handleChange={value => {
                                                    var temp = [...feedbacks]
                                                    temp[index] = value;
                                                    setFeedbacks(temp);
                                                }}
                                                disabled={Boolean(tripHistory.feedback)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={2}>
                                            {
                                                !Boolean(tripHistory.feedback) && (
                                                    <Button
                                                        fullWidth
                                                        color='secondary'
                                                        variant='contained'
                                                        className={classes.feedbackButton}
                                                        onClick={() => handleFeedbackClick(tripHistory.requestId, feedbacks[index])}>
                                                        ثبت بازخورد
                                                    </Button>
                                                )
                                            }
                                        </Grid>
                                    </Grid>
                                )
                            }
                            <Grid style={{ paddingRight: 10 }} item xs={12}>
                                <h5>{`مبدا: ${tripHistory.startPointAddress}`}</h5>
                            </Grid>
                            <Grid style={{ paddingRight: 10 }} item xs={12}>
                                <h5>{`مقصدها:`}</h5>
                                <ul>
                                    {
                                        tripHistory.destinationsAddress.map((address, index) =>
                                            <li key={index}><h5>{address}</h5></li>
                                        )
                                    }
                                </ul>
                            </Grid>
                            {
                                tripHistory.vehicleCode && (
                                    <>
                                        <Grid style={{ paddingRight: 10 }} item xs={6}>
                                            <h5>{`راننده: ${tripHistory.driverName + ' ' + tripHistory.driverFamily}`}</h5>
                                        </Grid>
                                        <Grid style={{ paddingRight: 10 }} item xs={6}>
                                            <h5>{`پلاک: ${tripHistory.vehiclePlate}`}</h5>
                                        </Grid>
                                    </>
                                )
                            }
                            <Grid style={{ paddingRight: 10 }} item xs={6}>
                                <h5>{`هزینه: ${tripHistory.price.toLocaleString('fa-IR')} ریال`}</h5>
                            </Grid>
                            <Grid style={{ paddingRight: 10 }} item xs={6}>
                                <h5>{`توقف: ${formatStop(tripHistory.stop)}`}</h5>
                            </Grid>
                            <Grid style={{ paddingRight: 10 }} item xs={6}>
                                <h5>{`وضعیت: ${formatRequestStatus(tripHistory.status)}`}</h5>
                            </Grid>
                        </Grid>
                    )
                }

            </div>
        )
    }

    const getComponent = () => {
        switch (selectedItem) {
            case null:
                return menuComopnent();
            case "userPanel":
                return userPanelComponent();
            case "trips":
                return tripsHistoryComponent();
        }
    }

    return (
        getComponent()
    )
}