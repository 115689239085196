import backgroundSvg from '../../assets/images/background.svg';
import backgroundTransparentSvg from '../../assets/images/backgroundTransparent.svg';
import carSvg from '../../assets/images/icon/car.svg';
import defaultSvg from '../../assets/images/icon/default.svg';
import driverSvg from '../../assets/images/icon/driver.svg';
import personSvg from '../../assets/images/icon/person.svg';
import SourceChoose from '../../assets/images/icon/source choose.svg';
import DestinationChoose from '../../assets/images/icon/destination choose.svg';
import { getPointColor } from '../../common/util/formatter';
import { loadImage, prepareIcon } from './mapUtil';

export const mapIcons = {
  car: carSvg,
  default: defaultSvg,
  destination: DestinationChoose,
  driver: driverSvg,
  person: personSvg,
  source: SourceChoose
};

export const mapImages = {};

export default async () => {
  const background = await loadImage(backgroundSvg);
  mapImages.background = await prepareIcon(background);
  const backgroundTransparent = await loadImage(backgroundTransparentSvg);

  await Promise.all(Object.keys(mapIcons).map(async (category) => {
    const results = [];
    results.push(loadImage(mapIcons[category]).then((icon) => {
      mapImages[`${category}`] = prepareIcon(backgroundTransparent, icon, getPointColor(category));
    }));

    await Promise.all(results);
  }));
};
