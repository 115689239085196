import { useCallback, useEffect, useId, useState } from 'react';
import { map } from './core/MapView';

const MapPositions = ({ positions, onClick }) => {
  const id = useId();
  const iconScale = 1;
  const currentMapZoom = map.getZoom();

  const [showCars, setShowCars] = useState(false);

  useEffect(() => {
    if (currentMapZoom < process.env.REACT_APP_MAP_ZOOM_THRESHOLD)
      setShowCars(false);
    else
      setShowCars(true);
  }, [currentMapZoom])

  const createFeature = (position) => {
    if (position[0] === 'source' || position[0] === 'destination' || position[0] === 'person' || position[0] === 'driver') {
      return {
        category: position[0],
        course: position[1].course || 0,
        name: position[0] === 'source' ? 'مبدا' : position[0] === 'destination' ? 'مقصد' : ''
      };
    }
    else {
      return {
        category: 'car',
        course: position[1].course || 0,
      };
    }
  };

  const onMouseEnter = () => map.getCanvas().style.cursor = 'pointer';
  const onMouseLeave = () => map.getCanvas().style.cursor = '';

  const onMapClick = useCallback((event) => {
    if (!event.defaultPrevented) {
      onClick();
    }
  }, [onClick]);

  const onMarkerClick = useCallback((event) => {
    event.preventDefault();
    const feature = event.features[0];
    if (onClick) {
      onClick(feature.properties.category, feature.properties.category);
    }
  }, [onClick]);

  useEffect(() => {
    map.addSource(id, {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [],
      },
    });
    map.addLayer({
      id,
      type: 'symbol',
      source: id,
      filter: ['!has', 'point_count'],
      layout: {
        'icon-image': '{category}',
        'icon-allow-overlap': true,
        'icon-anchor': 'center',
        'icon-size': iconScale,
        'icon-rotate': { "type": "identity", "property": "course" },
      },
      paint: {
        'icon-opacity': 0.9,
      },
    });

    map.on('mouseenter', id, onMouseEnter);
    map.on('mouseleave', id, onMouseLeave);
    map.on('click', id, onMarkerClick);
    map.on('click', onMapClick);

    return () => {
      map.off('mouseenter', id, onMouseEnter);
      map.off('mouseleave', id, onMouseLeave);
      map.off('click', id, onMarkerClick);
      map.off('click', onMapClick);

      if (map.getLayer(id)) {
        map.removeLayer(id);
      }
      if (map.getSource(id)) {
        map.removeSource(id);
      }
    };
  }, [onMarkerClick, showCars]);

  useEffect(() => {
    var temp = { ...positions };
    var features = [];
    features = temp.destinations.map(des => {
      return {
        type: 'Feature',
        geometry: { type: 'Point', coordinates: [des.lon, des.lat], name: 'مقصد' },
        properties: createFeature(['destination', des])
      }
    })
    delete temp.destinations;

    features.push(
      ...Object.entries(temp).map(position =>
        position[0] === 'source' || position[0] === 'person' || position[0] === 'driver' ? {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [position[1].lon, position[1].lat]
          },
          properties: createFeature(position),
        }
          :
          showCars ?
            {
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: [position[1].lon, position[1].lat],
              },
              properties: createFeature(position),
            }
            : {}
      )
    )
    map.getSource(id).setData({
      type: 'FeatureCollection',
      features: features
    })

  }, [positions, showCars]);

  return null;
};

export default MapPositions;
