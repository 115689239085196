import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup as MuiRadioGroup } from "@mui/material";

export default function RadioGroup({ title, options, value, size, disabled, onChange }) {

    return (
        <FormControl disabled={disabled}>
            <FormLabel>{title}</FormLabel>
            <MuiRadioGroup
                value={value}
                onChange={event => onChange(event.target.value)}
                title={title}
                row
            >
                {
                    options.map(
                        option =>
                            <FormControlLabel
                                label={option.label}
                                key={option.value}
                                value={option.value}
                                control={<Radio size={size ? size : 'medium'} />}
                            />
                    )
                }
            </MuiRadioGroup>
        </FormControl>
    )
}