import { Button, CircularProgress, Grid, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import landingPageBackground from "../../assets/landing-page-background.jpg";
import { HttpClient } from '../../common/HttpClient';
import { passengerRestUrl } from '../../common/restUrl';
import RadioGroup from '../../components/Radio/RadioGroup';
import { sessionActions } from '../../store';

const useStyles = makeStyles({
    root: {
        textAlign: 'center',
        justifyContent: 'center'
    },
    img: {
        height: 350,
        width: 350
    },
    title: {
        marginBottom: "20px !important"
    },
    marginBottom: {
        marginBottom: '10px !important'
    }
});

export default function Register() {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const classes = useStyles();

    const [step, setStep] = useState('register');
    const [serverTotpCode, setServerTotpCode] = useState(null);
    //gender : male = 1 , female = 0
    const [inputValues, setInputValues] = useState({ firstName: '', lastName: '', gender: 1, mobileNumber: '', email: '', nationalCode: '', totpCode: '' })
    const [isLoading, setIsLoading] = useState(false);

    const handleRegister = () => {
        setIsLoading(true);
        var url, body;
        if (step === 'register') {
            url = passengerRestUrl.login;
            body = { mobileNumber: inputValues.mobileNumber, register: true };
        }
        else {
            url = passengerRestUrl.register;
            body = { ...inputValues }
        }
        HttpClient.Post(url, JSON.stringify(body))
            .then(response => {
                if (step === 'register') {
                    setStep('smsValidation')
                    setInputValues({ ...inputValues, totpCode: serverTotpCode })
                } else {
                    dispatch(sessionActions.updateUser(response))
                    navigate("/home", { replace: true })
                }
                setIsLoading(false);
            }).catch(error => { setIsLoading(false); toast.error(error) })
    }

    return (
        <Grid container className={classes.root}>
            <Grid item xs={12} >
                <img src={landingPageBackground} className={classes.img} />
            </Grid>
            <Grid item xs={12} className={classes.title}>
                <Typography variant='h5'> {"ثبت نام مسافر"}</Typography>
            </Grid>
            {
                step === "register" && (
                    <>
                        <Grid item xs={8} className={classes.marginBottom}>
                            <TextField
                                fullWidth
                                variant='outlined'
                                value={inputValues.firstName}
                                label={"نام"}
                                onChange={event => setInputValues({ ...inputValues, firstName: event.target.value })}
                                type="text"
                            />
                        </Grid>
                        <Grid item xs={8} className={classes.marginBottom}>
                            <TextField
                                fullWidth
                                variant='outlined'
                                value={inputValues.lastName}
                                label={"نام خانوادگی"}
                                onChange={event => setInputValues({ ...inputValues, lastName: event.target.value })}
                                type="text"
                            />
                        </Grid>
                        <Grid item xs={8} className={classes.marginBottom}>
                            <TextField
                                fullWidth
                                variant='outlined'
                                value={inputValues.nationalCode}
                                label={"کد ملی"}
                                onChange={event => setInputValues({ ...inputValues, nationalCode: event.target.value })}
                                type="number"
                            />
                        </Grid>
                        <Grid item xs={8} className={classes.marginBottom}>
                            <TextField
                                fullWidth
                                variant='outlined'
                                value={inputValues.mobileNumber}
                                label={"شماره موبایل"}
                                onChange={event => setInputValues({ ...inputValues, mobileNumber: event.target.value })}
                                type="tel"
                            />
                        </Grid>
                        <Grid item xs={8} className={classes.marginBottom}>
                            <RadioGroup
                                title={'جنسیت'}
                                options={[{ label: 'مرد', value: 1 }, { label: 'زن', value: 0 }]}
                                value={inputValues.gender}
                                size={'small'}
                                onChange={value => setInputValues({ ...inputValues, gender: value })}
                            />
                        </Grid>
                    </>
                )
            }
            {
                step === "smsValidation" && (
                    <Grid item xs={8} className={classes.marginBottom}>
                        <TextField
                            fullWidth
                            variant='outlined'
                            value={inputValues.totpCode}
                            label="کد تایید پیامک شده را وارد کنید"
                            onChange={event => setInputValues({ ...inputValues, totpCode: event.target.value })}
                            type="number"
                        />
                    </Grid>
                )
            }
            <Grid item xs={8} className={classes.marginBottom}>
                <Button
                    fullWidth
                    variant='contained'
                    color='info'
                    onClick={handleRegister}
                >
                    {
                        !isLoading ?
                            step === "register" ? "ثبت نام" : "تایید"
                            :
                            <CircularProgress size={25} />
                    }
                </Button>
            </Grid>
            <Grid item xs={8}>
                <Button
                    fullWidth
                    variant='contained'
                    color='info'
                    onClick={() => { navigate(-1) }}
                >
                    بازگشت
                </Button>
            </Grid>
        </Grid>
    )
}