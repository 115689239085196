export const userRestUrl = {
    session: "/api/session"
}

export const driverRestUrl = {
    login: "/api/driver/totp/send",
    verify: "/api/driver/totp/verify",
    profile: "/api/driver/profile",
    socketConnect: "/queue/driver"
}

export const passengerRestUrl = {
    login: "/api/passenger/totp/send",
    verify: "/api/passenger/totp/verify",
    register: "/api/passenger/register",
    profile: "/api/passenger/profile",
    nearestCabs: "/api/passenger/nearest-devices?lat={lat}&lon={lon}",
    socketConnect: "/queue/passenger"
}

export const tripRestUrl = {
    sourceArrival: "/api/trip/source-arrival?requestId={requestId}",
    destinationArrival: "/api/trip/destination-arrival?requestId={requestId}",
    passengerGettingOn: "/api/trip/passenger-getting-on?requestId={requestId}",
    getRoute: "/api/trip/route",
    getPrice: "/api/trip/pre-request",
    getTaxi: "/api/trip/request",
    acceptRequest: "/api/trip/accept-request?requestId={requestId}",
    cancelRequest: "/api/trip/cancel-request?requestId={requestId}",
    getHistory: "/api/trip/history?page={page}",
    feedback: "/api/trip/feedback"
}