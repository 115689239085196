import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import CallIcon from '@mui/icons-material/Call';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import PaymentIcon from '@mui/icons-material/Payment';
import SocialDistanceIcon from '@mui/icons-material/SocialDistance';
import StayCurrentPortraitIcon from '@mui/icons-material/StayCurrentPortrait';
import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Button, CircularProgress, FormControl, FormControlLabel, FormLabel, Grid, IconButton, Radio, RadioGroup, Switch, TextField, Tooltip, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from 'react';
import { AiFillCar, AiOutlineCreditCard } from 'react-icons/ai';
import { GrMoney } from 'react-icons/gr';
import { MdLocalTaxi, MdPayment } from 'react-icons/md';
import { RiPinDistanceFill } from 'react-icons/ri';
import { useSelector } from "react-redux";
import { toast } from 'react-toastify';
import { HttpClient } from '../../common/HttpClient';
import { passengerRestUrl, tripRestUrl } from "../../common/restUrl";
import { convertToPersianNumber, formatDistance, formatPrice, formatStop } from '../../common/util/formatter';
import PassengerMenu from "../../components/Menu/PassengerMenu";
import Rating from '../../components/Rating/Rating';
import FullScreenDialog from "../../components/dialog/FullScreenDialog";
import { map } from "../../map/core/MapView";
import PassengerMap from '../../map/view/PassengerMap';
import SourceChoose from '../../assets/images/icon/source choose.svg';
import DestinationChoose from '../../assets/images/icon/destination choose.svg';

const useStyles = makeStyles(() => ({
    container: {
        height: '100%',
        width: '100%',
        position: 'relative',
    },
    exitButton: {
        position: 'fixed',
        top: '20px',
        right: '20px',
    },
    locatorField: {
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        top: 'calc(50% - 32px)',
        left: 'calc(50% - 24px)',
        alignItems: 'center',
    },
    locatorText: {
        fontFamily: 'IranSans !important',
        fontSize: '14px !important',
        color: 'black !important',
        fontWeight: 'bold !important',
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        width: '50px',
        top: 'calc(50% - 30px)',
        left: 'calc(50% - 25px)',
        alignItems: 'center',
    },
    detailField: {
        position: "fixed",
        height: 'fit-content',
        width: 'calc(100% - 40px)',
        marginLeft: 20,
        marginRight: 20,
        borderRadius: 10,
        bottom: "2.5%",
        backgroundColor: '#FFFFFF',
        boxShadow: '0px 0px 10px #626262',
    },
    setFontSize: {
        fontSize: '16px',
    },
}));

const states = {
    "SOURCE_CHOOSE": "SOURCE_CHOOSE",
    "DESTINATION_CHOOSE": "DESTINATION_CHOOSE",
    "GET_PRICE": "GET_PRICE",
    "WAITING_REQEST_ACCEPT": "WAITING_REQEST_ACCEPT",
    "REQUEST_ACCEPTED": "REQUEST_ACCEPTED",
    "DRIVER_ARRIVED": "DRIVER_ARRIVED",
    "PASSENGER_BOARDED": "PASSENGER_BOARDED",
    "TRIP_OVER": "TRIP_OVER"
}

const stopRange = [
    "بدون توقف", "5-10دقیقه", "10-15دقیقه", "15-20دقیقه"
]


const waitingData = "در حال بارگزاری ..."

export default function Passenger() {
    const classes = useStyles();

    const socket = useSelector(state => state.session.socket);
    const message = useSelector(state => state.session.response);
    const [requestDetails, setRequestDetails] = useState({ ...map.getCenter(), stop: 0, returnToSource: false })
    const [state, setState] = useState(states.SOURCE_CHOOSE);
    const [positions, setPositions] = useState({ source: {}, destinations: [] });
    const [tripDetails, setTripDetails] = useState({});
    const [feedback, setFeedback] = useState(0)
    const [feedbackDisable, setFeedbackDisable] = useState(false);
    const [menuDialogOpen, setMenuDialogOpen] = useState(false);
    const [tripOptionExpanded, setTripOptionExpanded] = useState(false);
    const [priceLoading, setPriceLoading] = useState(false);

    const handleMoveEnd = () => {
        if (socket && state === states.SOURCE_CHOOSE) {
            handleGetNearbyTaxis();
        }
    }

    useEffect(() => {
        map.on('moveend', handleMoveEnd)
        return () => map.off('moveend', handleMoveEnd)
    }, [socket, state])

    useEffect(() => {
        switch (state) {
            case states.TRIP_OVER:
                toast.info("سفر با موفقیت به اتمام رسید");
                break;
            default:
                break;
        }
    }, [state])


    useEffect(() => {
        if (message.type === "NEAREST_DEVICES_MESSAGE") {
            let devices = message.message.content;
            if (devices.length > 0) {
                let temp = { ...positions };
                for (let i = 0; i < devices.length; i++) {
                    temp = { ...temp, [devices[i].deviceCode]: { lon: devices[i].lon, lat: devices[i].lat, course: devices[i].course } }
                }
                setPositions(temp);
            }
            else {
                clearNearestDevices();
            }
        }
        else if (message.type === "ACCEPT_REQUEST_TAXI_MESSAGE") { //Driver Accepted
            clearNearestDevices();
            setTripDetails(message.message);
            setState(states.REQUEST_ACCEPTED);
        }
        else if (message.type === "CANCEL_REQUEST_TAXI_MESSAGE") {
            setTripDetails({});
            handleReturn();
            if (message.message.canceledBy === "BY_PASSENGER") {
                // toast.info("درخواست سفر لغو شد");
            }
            else if (message.message.canceledBy === "BY_DRIVER") {
                toast.info("درخواست سفر توسط راننده لغو شد");
            }
            else if (message.message.canceledBy === "OPERATOR") {
                toast.info("درخواست سفر توسط مرکز لغو شد");
            }
            else if (message.message.canceledBy === "TIMEOUT") {
                toast.info("درخواست شما منقضی شده است");
            }
            else {
                toast.info("درخواست سفر لغو شد");
            }
        }
        else if (message.type === "START_REQUEST_TYPE") {
            setTripDetails(message.message);
            setPositions({
                source: { lat: message.message.startPoint.lat, lon: message.message.startPoint.lon },
                destinations: message.message.destinations
            })
            setState(states.WAITING_REQEST_ACCEPT);
        }
        else if (message.type === "ACCEPT_REQUEST_TYPE") { //Page Refreshed And Driver Accepted Before
            if (message.message.acceptRequestState === "ACCEPT") {
                setState(states.REQUEST_ACCEPTED);
                setPositions({
                    ...positions,
                    source: { lat: message.message.startPoint.lat, lon: message.message.startPoint.lon },
                    destinations: message.message.destinations
                })
            }
            else if (message.message.acceptRequestState === "DRIVER_ARRIVE") {
                setState(states.DRIVER_ARRIVED);
                setPositions({
                    ...positions,
                    source: { lat: message.message.startPoint.lat, lon: message.message.startPoint.lon },
                    destinations: message.message.destinations
                })
            }
            else if (message.message.acceptRequestState === "PASSENGER_GETTING_ON") {
                setState(states.PASSENGER_BOARDED);
                setPositions({
                    ...positions,
                    source: { lat: message.message.startPoint.lat, lon: message.message.startPoint.lon },
                    destinations: message.message.destinations
                })
            }
            setTripDetails(message.message);
        }
        else if (message.type === "ARRIVE_DRIVER_MESSAGE") {
            setState(states.DRIVER_ARRIVED);
        }
        else if (message.type === "PASSENGER_GETTING_ON_MESSAGE") {
            setTripDetails({ ...tripDetails, onGoingDestinationIdx: 0 })
            setState(states.PASSENGER_BOARDED);
        }
        else if (message.type === "DESTINATION_ARRIVAL_MESSAGE") {
            const onGoingDesIdx = Number(message.message.content);
            if (onGoingDesIdx == -1) {
                setState(states.TRIP_OVER);
            } else {
                setTripDetails({ ...tripDetails, onGoingDestinationIdx: Number(onGoingDesIdx) })
            }
        }
        else if (message.type === "POSITION_MESSAGE") {
            setPositions({ ...positions, car: { lat: message.message.lat, lon: message.message.lon, course: message.message.course } });
        }
        else if (message.type === "ERROR_MESSAGE")
            toast.error(message.message.message)
        else
            console.log(message);
    }, [message])

    const clearNearestDevices = () => {
        let reorder = {};
        reorder.source = positions.source;
        reorder.destinations = positions.destinations;
        if (positions.car) {
            reorder.car = positions.car;
        }
        setPositions(reorder);
    }

    const handleGetPrice = async (destinations, stop, returnToSource) => {
        setPriceLoading(true);
        setState(states.GET_PRICE);
        const startPoint = { lat: positions.source.lat, lon: positions.source.lon };
        const body = JSON.stringify({ startPoint, destinations: destinations, stop: stop, returnToSource: returnToSource });
        HttpClient.Post(tripRestUrl.getPrice, body).then(response => {
            setTripDetails(response);
            setPriceLoading(false);
        }).catch(error => toast.error(error))
    }

    const handleGetTaxi = () => {
        const startPoint = { lat: positions.source.lat, lon: positions.source.lon };
        const body = JSON.stringify({ startPoint, destinations: positions.destinations, stop: requestDetails.stop, returnToSource: requestDetails.returnToSource })
        HttpClient.Post(tripRestUrl.getTaxi, body).then(response => {
            setTripDetails(response);
            setState(states.WAITING_REQEST_ACCEPT);
        }).catch(error => toast.error(error))
    }

    const handleGetNearbyTaxis = () => {
        let currentPoint = map.getCenter();
        const url = passengerRestUrl.nearestCabs.replace('{lat}', currentPoint.lat).replace('{lon}', currentPoint.lng)
        HttpClient.Get(url).then(devices => {
            if (devices.length > 0) {
                let temp = { ...positions };
                for (let i = 0; i < devices.length; i++) {
                    temp = { ...temp, [devices[i].deviceCode]: { lon: devices[i].lon, lat: devices[i].lat, course: devices[i].course } }
                }
                setPositions(temp);
            }
            else {
                clearNearestDevices();
            }
        }).catch(error => toast.error(error))
    }

    const handleReset = () => {
        setState(states.SOURCE_CHOOSE)
        let temp = { ...positions };
        temp.source = {};
        temp.destinations = [];
        delete temp.car;
        setPositions(temp);
        setFeedback(0);
        setFeedbackDisable(false);
    }

    const handleReturn = () => {
        var temp = { ...positions }
        if (temp.destinations.length > 0) {
            temp.destinations.pop();
            delete temp.car;
            setPositions(temp);
            setState(states.DESTINATION_CHOOSE);
        } else {
            handleReset();
        }
    }

    const handleCancelRequest = () => {
        const url = tripRestUrl.cancelRequest.replace("{requestId}", tripDetails.requestId)
        HttpClient.Put(url).then(_ => {
            setTripDetails({});
            handleReturn();
            toast.info("درخواست سفر لغو شد");
        }).catch(error => toast.error(error))
    }

    const handleFeedbackClick = () => {
        if (feedback != 0) {
            var body = JSON.stringify({ requestId: tripDetails.requestId, feedback: Number(feedback) });
            HttpClient.Put(tripRestUrl.feedback, body).then(_ => {
                setFeedbackDisable(true);
                toast.info('بازخورد شما با موفقیت ثبت شد')
            }).catch(error => toast.error(error))
        }
    }

    const getDestinationLabel = () => {
        var destinations = positions.destinations;
        var label = 'مقصد ';
        if (destinations.length > 0) {
            label += destinations.length + 1;
        }
        return label;
    }

    const handleDestinationChoose = async () => {
        const loc = map.getCenter();
        var destinations = [...positions.destinations]
        destinations.push({ lat: loc.lat, lon: loc.lng });
        handleGetPrice(destinations, requestDetails.stop, requestDetails.returnToSource);
        setPositions({ ...positions, destinations: destinations });
    }

    const handleReturnToSourceChange = (event) => {
        var checked = event.target.checked;
        handleGetPrice(positions.destinations, requestDetails.stop, checked);
        setRequestDetails({ ...requestDetails, returnToSource: checked })
    }

    const getDetailField = () => {
        if (state === states.SOURCE_CHOOSE) {
            return (
                <Button variant="contained" fullWidth onClick={() => {
                    const loc = map.getCenter();
                    setPositions({ ...positions, source: { lat: loc.lat, lon: loc.lng } });
                    setState(states.DESTINATION_CHOOSE);
                }}>انتخاب مبدا</Button>
            )
        }
        else if (state === states.DESTINATION_CHOOSE) {
            return (
                <Grid container style={{ textAlign: 'center', justifyContent: 'space-evenly' }}>
                    <Grid item xs={12}>
                        <Button variant="contained" fullWidth color='primary' onClick={handleDestinationChoose}>{`انتخاب ${getDestinationLabel()}`}</Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant="contained" fullWidth color='secondary' onClick={handleReturn}>بازگشت</Button>
                    </Grid>
                </Grid>
            )
        }
        else if (state === states.GET_PRICE) {
            return (
                <Grid container style={{ textAlign: "center", justifyContent: 'space-evenly' }}>
                    <Grid item xs={12} sm={6} style={{ padding: '6px 16px' }}>
                        <Typography variant='body1' className={classes.setFontSize} >
                            <SocialDistanceIcon />
                            {' '}
                            مسافت
                            :
                            <span style={{ alignSelf: 'center' }}>
                                {priceLoading ? 'در حال بارگزاری...' : formatDistance(tripDetails?.distance)}
                            </span>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} style={{ padding: '10px 16px' }}>
                        <Typography variant='body1' className={classes.setFontSize}>
                            <PaymentIcon />
                            {' '}
                            مبلغ
                            :
                            <span style={{ alignSelf: 'center' }}>
                                {priceLoading ? 'در حال بارگزاری...' : formatPrice(tripDetails?.price?.toString())}
                            </span>
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Accordion expanded={tripOptionExpanded} >
                            <AccordionSummary onClick={() => setTripOptionExpanded(!tripOptionExpanded)} expandIcon={<ArrowDownwardIcon />}>
                                گزینه‌های سفر
                            </AccordionSummary>
                            <AccordionDetails>
                                <Autocomplete
                                    options={stopRange}
                                    value={stopRange[requestDetails.stop]}
                                    onChange={(_, value) => {
                                        const stop = stopRange.findIndex(s => s === value);
                                        handleGetPrice(positions.destinations, stop, requestDetails.returnToSource);
                                        setRequestDetails({ ...requestDetails, stop: stop });
                                    }}
                                    renderInput={(params) => <TextField {...params} label="توقف" variant="outlined" />}
                                />
                                <Grid container justifyContent={'space-between'}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={requestDetails.returnToSource}
                                                onChange={handleReturnToSourceChange}
                                            />
                                        }
                                        label="رفت و برگشت"
                                    />
                                    <Button onClick={() => setState(states.DESTINATION_CHOOSE)}>
                                        انتخاب مقصد بعدی
                                    </Button>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                    <Grid item xs={12}>
                        {
                            priceLoading ? <CircularProgress /> :
                                <Button disabled={priceLoading} color="primary" variant="contained" fullWidth onClick={handleGetTaxi}>
                                    درخواست
                                    <MdLocalTaxi fontSize={18} />
                                </Button>
                        }

                    </Grid>
                    <Grid item xs={12}>
                        <Button color="secondary" variant="contained" fullWidth onClick={handleReturn}>
                            بازگشت
                        </Button>
                    </Grid>
                </Grid>
            )
        }
        else if (state === states.WAITING_REQEST_ACCEPT) {
            return (
                <Grid container style={{ textAlign: 'center', justifyContent: 'space-evenly' }}>
                    <Grid item xs={12} style={{ padding: '6px 16px' }}>
                        <Typography variant='body1' className={classes.setFontSize} >
                            درخواست ارسال شد. لطفا منتظر بمانید...
                            {' '}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Button color="secondary" variant="contained" fullWidth onClick={handleCancelRequest}>
                            لغو درخواست
                        </Button>
                    </Grid>
                </Grid>
            )
        }
        else if (state === states.REQUEST_ACCEPTED) {
            return (
                <Grid container style={{ textAlign: 'justify', alignContent: 'center' }}>
                    <Grid item xs={12} style={{ padding: '6px 16px' }}>
                        <Typography variant='body1' className={classes.setFontSize} >
                            راننده:
                            {' '}
                            <RiPinDistanceFill />
                            :
                            <span style={{ alignSelf: 'center' }}>
                                {tripDetails?.driverName + ` ` + tripDetails?.driverFamily}
                            </span>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ padding: '6px 16px' }}>
                        <Typography variant='body1' className={classes.setFontSize} >
                            خودرو:
                            {' '}
                            <AiFillCar />
                            :
                            <span style={{ alignSelf: 'center' }}>
                                {tripDetails?.vehicleType}
                            </span>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ padding: '6px 16px' }}>
                        <Typography variant='body1' className={classes.setFontSize} >
                            پلاک:
                            {' '}
                            <AiOutlineCreditCard />
                            :
                            <span style={{ alignSelf: 'center' }}>
                                {convertToPersianNumber(tripDetails?.plate)}
                            </span>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant='body1'>
                            <StayCurrentPortraitIcon />
                            {' '}
                            موبایل
                            :
                            <span>
                                {
                                    Boolean(tripDetails.driverMobileNumber) ? convertToPersianNumber(tripDetails.driverMobileNumber.replace('+98', '0'))
                                        : waitingData
                                }
                            </span>
                            <a
                                href={`tel:${tripDetails.driverMobileNumber}`}
                                style={{ position: 'relative', top: 7 }}
                            >
                                <CallIcon />
                            </a>
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Button color="secondary" variant="contained" fullWidth onClick={handleCancelRequest}>
                            لغو سفر
                        </Button>
                    </Grid>
                </Grid>
            )
        }
        else if (state === states.DRIVER_ARRIVED) {
            return (
                <Grid container style={{ textAlign: 'center', justifyContent: 'space-evenly' }}>
                    <Grid item xs={12} style={{ padding: '6px 16px' }}>
                        <Typography variant='body1' className={classes.setFontSize} >
                            راننده به مبدا رسید و منتظر شماست
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Button color="secondary" variant="contained" fullWidth onClick={handleCancelRequest}>
                            لغو درخواست
                        </Button>
                    </Grid>
                </Grid>
            )
        }
        else if (state === states.PASSENGER_BOARDED) {
            return (
                <Grid container style={{ textAlign: 'center', justifyContent: 'space-evenly' }}>
                    <Grid item xs={12} style={{ padding: '6px 16px' }}>
                        <Typography variant='body1' className={classes.setFontSize}>
                            در مسیر مقصد
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant='body1' className={classes.setFontSize}>
                            مبلغ
                            {' '}
                            <GrMoney />
                            :
                            <span> {formatPrice(tripDetails?.price?.toString())}</span>
                        </Typography>
                        <Grid item xs={12} sm={6}>
                            <Typography variant='body1'>
                                {' '}
                                توقف
                                <HourglassTopIcon />
                                :
                                <span>
                                    {
                                        tripDetails.stop ? formatStop(tripDetails.stop)
                                            : waitingData
                                    }
                                </span>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl>
                            <FormLabel>روش پرداخت</FormLabel>
                            <RadioGroup
                                defaultValue="cash"
                                row
                            >
                                <FormControlLabel disabled value="online" control={<Radio />} label="آنلاین" />
                                <FormControlLabel checked value="cash" control={<Radio />} label="نقدی" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>
            )
        }
        else if (state === states.TRIP_OVER) {
            return (
                <Grid container style={{ textAlign: 'center', justifyContent: 'center' }}>
                    <Grid item xs={12} style={{ padding: '6px 16px', alignSelf: 'center' }} >
                        <Typography variant='body1' className={classes.setFontSize} >
                            راننده:
                            {' '}
                            <RiPinDistanceFill />
                            :
                            <span style={{ alignSelf: 'center' }}>
                                {tripDetails?.driverName + ` ` + tripDetails?.driverFamily}
                            </span>
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant='body1'>
                            نوع پرداخت
                            {' '}
                            <MdPayment />
                            :
                            نقدی
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='body1'>
                            مبلغ
                            {' '}
                            <GrMoney />
                            :
                            <span> {formatPrice(tripDetails?.price?.toString())}</span>
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='body1'>
                            {' '}
                            توقف
                            <HourglassTopIcon />
                            :
                            <span>
                                {
                                    tripDetails.stop ? formatStop(tripDetails.stop)
                                        : waitingData
                                }
                            </span>
                        </Typography>
                    </Grid>
                    <Grid item xs={6} style={{ padding: '6px 16px', alignSelf: 'center' }}>
                        <Typography variant='body1' className={classes.setFontSize} color="primary">
                            سفر به اتمام رسید
                        </Typography>
                    </Grid>
                    <Grid item xs={5} sm={2}>
                        <Rating
                            title={"ثبت بازخورد"}
                            defaultValue={0}
                            value={feedback}
                            handleChange={value => setFeedback(value)}
                            disabled={feedbackDisable}
                        />
                    </Grid>
                    <Grid item xs={5} sm={1} alignSelf={'flex-end'}>
                        <Button
                            fullWidth
                            color='secondary'
                            variant='contained'
                            onClick={handleFeedbackClick}
                            disabled={feedbackDisable}
                        >
                            ثبت
                        </Button>
                    </Grid>
                    <Grid item xs={12} style={{ padding: '6px 16px' }}>
                        <Button fullWidth variant='contained' className={classes.setFontSize} onClick={handleReset}>
                            شروع سفر جدید
                        </Button>
                    </Grid>
                </Grid>
            )
        }
        else {
            return (<></>)
        }
    }

    return (
        <div className={classes.container}>
            <PassengerMap positions={positions} />
            <div className={classes.exitButton}>
                <Tooltip title={"منوی کاربری"}>
                    <IconButton onClick={() => setMenuDialogOpen(true)} style={{ backgroundColor: 'white' }} >
                        <AccountCircleRoundedIcon fontSize='large' color={'info'} />
                    </IconButton>
                </Tooltip>
            </div>
            <div className={classes.locatorField}>
                {
                    state === states.SOURCE_CHOOSE ? <img src={SourceChoose} alt={'- -'} /> :
                        state === states.DESTINATION_CHOOSE ? <img src={DestinationChoose} alt={'- -'} />
                            : <></>
                }
            </div>
            <div className={classes.detailField}>
                {
                    getDetailField()
                }
            </div>
            <FullScreenDialog
                open={menuDialogOpen}
                onClose={() => setMenuDialogOpen(false)}
            >
                <PassengerMenu handleClose={() => setMenuDialogOpen(false)} />
            </FullScreenDialog>
        </div>
    )
}