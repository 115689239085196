import React, { createContext, useContext } from 'react';

const AppContext = createContext();

const reducer = (state, action) => {
    localStorage.setItem("theme", action.theme);
    return action;
}

export function AppWrapper({ children }) {
    let sharedState = {
        theme: localStorage.getItem("theme") || "passenger"
    }
    const [state, dispatch] = React.useReducer(reducer, sharedState);
    return (
        <AppContext.Provider value={{ state, dispatch }} >
            {children}
        </AppContext.Provider>
    );
}

export function useAppContext() {
    return useContext(AppContext);
}