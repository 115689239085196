import { LinearProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import Home from './app/home/Home';
import Landing from './app/landing/Landing';
import Login from './app/login/Login';
import Register from './app/register/Register';
import { HttpClient } from './common/HttpClient';
import { userRestUrl } from './common/restUrl';
import { sessionActions } from './store';

export default function Navigation() {
  let authenticated = useSelector(state => Boolean(state.session.user))
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    HttpClient.Get(userRestUrl.session).then(response => {
      if (!response)
        return;
      var attributes = response.attributes;
      delete response.attributes;
      dispatch(sessionActions.updateUser(response))
      if (attributes) {
        var type = attributes.state;
        delete attributes.state;
        dispatch(sessionActions.updateResponse({ type: type, message: attributes }));
      }
      setLoading(false);
    }).catch(() => setLoading(false))
  }, [])

  return (
    loading ?
      <LinearProgress />
      :
      <Routes>
        {authenticated ?
          <>
            <Route path="/home" element={<Home />} />
            <Route path="*" element={<Navigate to="/home" replace />} />
          </>
          :
          <>
            <Route path="/" element={<Landing />} />
            <Route exact path="passenger/login" element={<Login isDriver={false} />} />
            <Route exact path="passenger/register" element={<Register />} />
            <Route path="/driver/login" element={<Login isDriver={true} />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </>
        }
      </Routes >
  );
};