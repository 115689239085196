import CloseIcon from '@mui/icons-material/Close';
import { AppBar, Dialog, IconButton, Toolbar } from "@mui/material";

export default function FullScreenDialog({ open, onClose, ...props }) {
    return (
        <Dialog
            fullScreen
            open={open}
            onClose={onClose}
        >
            <AppBar  sx={{ position: 'relative'}}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick = {onClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            {props.children}
        </Dialog>
    )
}