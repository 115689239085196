import { useSelector } from "react-redux";
import Driver from "../driver/Driver";
import Passenger from "../passenger/Passenger";


export default function Home() {
    const isDriver = useSelector((state) => state.session.user?.type === 'DRIVER');

    if (isDriver)
        return (
            <Driver />
        )
    else
        return (
            <Passenger />
        )
}