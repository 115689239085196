import { BrowserRouter } from "react-router-dom";
import { Flip, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import Navigation from "./Navigation";
import SocketController from "./SocketController";

export default function App() {
  return (
    <>
      <BrowserRouter>
        <SocketController />
        <Navigation />
      </BrowserRouter>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={true}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        transition={Flip}
        toastStyle={{ zIndex: 7000 }}
      />
    </>
  );
}

