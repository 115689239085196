export default {
    MuiCssBaseline: {
        '@global': {
            '*::-webkit-scrollbar': {
                width: '4px',
                height: 4
            },
            '*::-webkit-scrollbar-track': {
                '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
            },
            '*::-webkit-scrollbar-thumb': {
                backgroundColor: 'orange',
                outline: '0px solid slategrey'
            }
        }
    },
    MuiPaper: {
        elevation1: {
            boxShadow: 'unset'
        },
        rounded: {
            borderRadius: 5
        }
    },
    MuiButton: {
        containedPrimary: {
            height: 65,
            color: 'white'
        }
    },

    MuiInputBase: {
        root: {
            height: 65,
        },
        input: {
            height: '26px',
        },

    },
    MuiInputLabel: {
        outlined: {
            transform: `translate(14px, 24px) scale(1)`
        }
    },
    MuiFormHelperText: {
        root: {
            '&$error': {
                color: '#ff5555'
            }
        },
    },
};
