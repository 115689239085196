import { MapUrl } from "../../common/Urls";

const styleCustom = ({ tiles, minZoom, maxZoom, attribution }) => {
  const source = {
    type: 'raster',
    tiles,
    attribution,
    tileSize: 256,
    minzoom: minZoom,
    maxzoom: maxZoom,
  };
  Object.keys(source).forEach((key) => source[key] === undefined && delete source[key]);
  return {
    version: 8,
    sources: {
      custom: source,
    },
    glyphs: '{fontstack}/{range}.pbf',
    layers: [{
      id: 'custom',
      type: 'raster',
      source: 'custom',
    }],
  };
};

export default () => {

  return [
    {
      id: 'osm',
      title: 'نقشه داخلی',
      style: styleCustom({
        tiles: [`${MapUrl}/{z}/{x}/{y}.png`],
        maxZoom: 19,
        attribution: '© <a target="_top" rel="noopener" href="https://gset.ir">GSET</a> contributors',
      }),
      available: true,
    },
  ];
};
