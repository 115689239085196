import { createTheme } from '@mui/material/styles';
import palette from './palette';
import dimensions from './dimensions';
import components from './components';
import overrides from './overrides';

const theme = createTheme({
  direction: 'rtl',
  palette,
  overrides,
  dimensions,
  components,
  typography: {
    "fontFamily": `"IranSans", "Roboto", "Helvetica", "Arial", sans-serif`,
    "fontSize": 14,
    "fontWeightLight": 300,
    "fontWeightRegular": 400,
    "fontWeightMedium": 500
  }
});

export default theme;
