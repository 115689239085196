import { useEffect } from 'react';
import { MapStartPosition } from '../../common/Urls';
import { map } from '../core/MapView';

const MapDefaultCamera = ({ defaultPosition, defaultZoom }) => {
  const defaultLatitude = defaultPosition ? defaultPosition.lat : MapStartPosition.lat;
  const defaultLongitude = defaultPosition ? defaultPosition.lon : MapStartPosition.lon;
  const zoom = defaultZoom ? defaultZoom : MapStartPosition.zoom;

  useEffect(() => {
    map.jumpTo({
      center: [defaultLongitude, defaultLatitude],
      zoom: zoom,
    });
  }, []);

  return null;
};

export default MapDefaultCamera;
