
import { Button, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import landingPageBackground from "../../assets/landing-page-background.jpg";

const useStyles = makeStyles({
    root: {
        textAlign: 'center',
        justifyContent: 'center'
    },
    img: {
        height: 350,
        width: 350
    },
    title: {
        marginBottom: "20px !important"
    },
    appName: {
        color: '#11D2FD'
    },
    marginBottom: {
        marginBottom: "10px !important"
    }
});

export default function Landing() {
    const classes = useStyles();
    const navigate = useNavigate()

    const handleClick = (name) => {
        localStorage.setItem("theme", name);
        const rout = name + "/login";
        navigate(rout);
    }

    return (
        <Grid container className={classes.root}>
            <Grid item xs={12} >
                <img src={landingPageBackground} className={classes.img} />
            </Grid>
            <Grid item xs={12} className={classes.title}>
                <Typography variant='h5'>به {' '}
                    <strong className={classes.appName}>{process.env.REACT_APP_APPLICATION_NAME}{' '}</strong>
                    خوش آمدید!
                </Typography>
            </Grid>
            <Grid item xs={8} className={classes.marginBottom}>
                <Button
                    fullWidth
                    variant='outlined'
                    color='primary'
                    onClick={() => handleClick("passenger")}
                >
                    مسافر هستم
                </Button>
            </Grid>
            <Grid item xs={8}>
                <Button
                    fullWidth
                    variant='outlined'
                    color='secondary'
                    onClick={() => handleClick("driver")}
                >
                    راننده هستم
                </Button>
            </Grid>
        </Grid>
    )
}

{/* 
    <Hidden only='xs'>
        <LandingDesktop />
    </Hidden>
    <Hidden smUp>
        <LandingMobile />
    </Hidden>
 */}