import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { sessionReducer as session } from './session';

const reducer = combineReducers({
  session,
});

export { sessionActions } from './session';

const rootReducer = (state, action) => {
  if (action.type === 'RESET_APP') {
    state = undefined
  }
  return reducer(state, action);
}

export default configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});
