import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import CallIcon from '@mui/icons-material/Call';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import PaymentIcon from '@mui/icons-material/Payment';
import PaymentsIcon from '@mui/icons-material/Payments';
import SocialDistanceIcon from '@mui/icons-material/SocialDistance';
import StayCurrentPortraitIcon from '@mui/icons-material/StayCurrentPortrait';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import { Button, CircularProgress, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { TiTick, TiTimes } from "react-icons/ti";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import PassengerSvg from '../../assets/images/passenger.svg';
import { HttpClient } from '../../common/HttpClient';
import { tripRestUrl } from "../../common/restUrl";
import { convertToPersianNumber, formatDistance, formatPrice, formatStop, formatTime } from "../../common/util/formatter";
import DriverMenu from '../../components/Menu/DriverMenu';
import FullScreenDialog from '../../components/dialog/FullScreenDialog';
import DriverMap from "../../map/view/DriverMap";
import NavigationIcon from '@mui/icons-material/Navigation';

const useStyles = makeStyles(() => ({
    container: {
        height: '100%',
        width: '100%',
        display: 'flex',
    },
    detailField: {
        height: 'fit-content',
        width: 'calc(100% - 20px)',
        marginLeft: 10,
        marginRight: 10,
        borderRadius: 10,
        position: 'absolute',
        bottom: 10,
        backgroundColor: '#FFFFFF',
        boxShadow: '0px 0px 10px #626262',
    },
    circularProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
    },
    exitButton: {
        position: 'fixed',
        top: '20px',
        right: '20px',
    },
    navigationButton: {
        position: 'absolute',
        top: -50,
        left: '5px',
        borderRadius: '20px',
        padding: 10

    }
}));

const states = {
    "WAITING": "WAITING",
    "ROUTING": "ROUTING",
    "TRAVELLING_TO_ORIGIN": "TRAVELLING_TO_ORIGIN",
    "IN_PASSENGER_PLACE": "IN_PASSENGER_PLACE",
    "TRAVELLING_TO_DESTINATION": "TRAVELLING_TO_DESTINATION",
    "TRIP_OVER": "TRIP_OVER"
}

const waitingData = "در حال بارگزاری ..."

export default function Driver() {
    const classes = useStyles();
    const message = useSelector(state => state.session.response);

    const [state, setState] = useState(states.WAITING);
    const [initialed, setInitialed] = useState(false);
    const [positions, setPositions] = useState({ source: {}, destinations: [] });
    const [currentPosition, setCurrentPosition] = useState(null);
    const [tripRequests, setTripRequests] = useState([]);
    const [acceptedTrip, setAcceptedTrip] = useState(null);
    const [currentSlide, setCurrentSlide] = useState(null);
    const [route, setRoute] = useState([]);
    const [routeDetail, setRouteDetail] = useState({});
    const [showEntireRoute, setShowEntireRoute] = useState(undefined);
    const [menuDialogOpen, setMenuDialogOpen] = useState(false);


    useEffect(() => {
        switch (state) {
            case states.TRIP_OVER:
                removeRequests(acceptedTrip, "trip_ended");
                break;
            case states.TRAVELLING_TO_ORIGIN:
                var desIdx = acceptedTrip?.onGoingDestinationIdx;
                if (Number.isInteger(desIdx) && currentPosition) {
                    getRoute(currentPosition, [acceptedTrip.startPoint])
                }
                break;
            case states.TRAVELLING_TO_DESTINATION:
                var desIdx = acceptedTrip?.onGoingDestinationIdx;
                if (Number.isInteger(desIdx) && currentPosition) {
                    getRoute(currentPosition, [acceptedTrip.destinations[desIdx]])
                }
                break;
            case states.IN_PASSENGER_PLACE:
                setRoute([]);
            default:
                break;
        }
    }, [state, acceptedTrip, currentPosition])

    useEffect(() => {
        if (message) {
            if (message.type === "REQUEST_TAXI_MESSAGE") {
                if (state !== states.TRIP_OVER) {
                    if (!tripRequests.some(trip => trip.requestId === message.message.requestId)) {
                        let temp = [...tripRequests]
                        temp.unshift(message.message);
                        setPositions({
                            ...positions,
                            source: { lat: message.message.startPoint.lat, lon: message.message.startPoint.lon },
                            destinations: message.message.destinations
                        })
                        setTripRequests(temp);
                    }
                }
            }
            else if (message.type === "ACCEPT_REQUEST_TYPE") { //session
                if (message.message.acceptRequestState === "ACCEPT") {
                    setState(states.TRAVELLING_TO_ORIGIN);
                }
                else if (message.message.acceptRequestState === "DRIVER_ARRIVE") {
                    setState(states.IN_PASSENGER_PLACE);
                }
                else if (message.message.acceptRequestState === "PASSENGER_GETTING_ON") {
                    setState(states.TRAVELLING_TO_DESTINATION);
                }
                setPositions({
                    ...positions,
                    source: { lat: message.message.startPoint.lat, lon: message.message.startPoint.lon },
                    destinations: message.message.destinations
                })
                setAcceptedTrip(message.message);
            }
            else if (message.type === "CANCEL_REQUEST_TAXI_MESSAGE") {
                removeRequests(message.message, "other");
            }
            else if (message.type === "POSITION_MESSAGE") {
                var curPosition = { lat: message.message.lat, lon: message.message.lon };
                setCurrentPosition(curPosition);
                setPositions({ ...positions, driver: { lat: curPosition.lat, lon: curPosition.lon, course: message.message.course } });
                setInitialed(true);
            }
            else if (message.type === "ROUTING_MESSAGE") {
                let response = JSON.parse(message.message.content);
                if (response && response.code === "Ok") {
                    let temp = response.route.legs[0];
                    setRouteDetail({
                        distance: temp.distance, duration: temp.duration,
                        summary: temp.summary
                    });
                    let ans = [];
                    for (let i = 0; i < temp.steps.length; i++) {
                        for (let j = 0; j < temp.steps[i].intersections.length; j++) {
                            ans.push({ lat: temp.steps[i].intersections[j].location[1], lon: temp.steps[i].intersections[j].location[0] })
                        }
                    }
                    setRoute(ans);
                }
            }
            else if (message.type === "DESTINATION_ARRIVAL_MESSAGE") {

            }
            else if (message.type === "ERROR_MESSAGE")
                toast.error(message.message.message)
            else
                console.log(message)
        }
    }, [message])


    const getRoute = async (startPoint, destinations) => {
        const body = {
            startPoint: { ...startPoint },
            destinations: destinations
        }
        HttpClient.Post(tripRestUrl.getRoute, JSON.stringify(body)).then(route => {
            let temp = route.legs[0];
            setRouteDetail({
                distance: temp.distance, duration: temp.duration,
                summary: temp.summary
            });
            let ans = [];
            for (let i = 0; i < temp.steps.length; i++) {
                for (let j = 0; j < temp.steps[i].intersections.length; j++) {
                    ans.push({ lat: temp.steps[i].intersections[j].lon, lon: temp.steps[i].intersections[j].lat })
                }
            }
            setRoute(ans);
        }).catch(error => toast.error(error))
    }

    const acceptRequest = (request) => {
        const url = tripRestUrl.acceptRequest.replace('{requestId}', request.requestId);
        HttpClient.Put(url)
            .then(response => {
                setAcceptedTrip(response);
                setState(states.TRAVELLING_TO_ORIGIN);
            })
            .catch(error => toast.error(error))

    }

    const handleCancelTrip = () => {
        const url = tripRestUrl.cancelRequest.replace("{requestId}", acceptedTrip.requestId)
        HttpClient.Put(url).then(_ => {
            removeRequests({
                ...acceptedTrip, canceledBy: "BY_DRIVER"
            }, "other");
        }).catch(error => toast.error(error))
    }

    const removeSourceDestinationMarker = () => {
        let temp = { ...positions };
        delete temp.source;
        temp.destinations = [];
        setPositions(temp);
    }

    const removeFromRequestsList = (request) => {
        let idx = tripRequests.findIndex(tripRequest => tripRequest.requestId === request.requestId)
        let temp = [...tripRequests];
        if (idx >= 0)
            temp.splice(idx, 1);
        setTripRequests(temp);
    }

    const removeRequests = (request, type) => {
        if (!request || tripRequests.length === 0)
            return;
        if (type === "delete_trip_request") {
            removeFromRequestsList(request);
            removeSourceDestinationMarker();
            toast.info("درخواست سفر حذف شد");
        }
        else if (type === "trip_ended") {
            removeFromRequestsList(request);
            setRoute([]);
            setRouteDetail({});
            removeSourceDestinationMarker();
            setState(states.WAITING);
            toast.info("سفر با موفقیت به اتمام رسید");
        }
        else {
            removeFromRequestsList(request);
            if (currentSlide?.requestId === request.requestId) {
                removeSourceDestinationMarker();
            }
            if (acceptedTrip?.requestId === request.requestId) {
                removeSourceDestinationMarker();
                setRoute([]);
                setRouteDetail({});
                setAcceptedTrip(null);
                setState(states.WAITING);
            }
            if (request.canceledBy === "BY_PASSENGER")
                toast.info("درخواست سفر توسط مسافر لغو شد");
            else if (request.canceledBy === "BY_DRIVER")
                toast.info("درخواست سفر لغو شد");
            else if (request.canceledBy === "OPERATOR")
                toast.info("درخواست سفر توسط مرکز لغو شد");
            else
                removeFromRequestsList(request);
        }
    }

    const handleArriveToPassenger = () => {
        const url = tripRestUrl.sourceArrival.replace('{requestId}', acceptedTrip.requestId);
        HttpClient.Put(url)
            .then(_ => {
                setState(states.IN_PASSENGER_PLACE);
            })
            .catch(error => toast.error(error))
    }

    const handlePassengerGetOn = () => {
        const url = tripRestUrl.passengerGettingOn.replace('{requestId}', acceptedTrip.requestId);
        HttpClient.Put(url)
            .then(_ => {
                setState(states.TRAVELLING_TO_DESTINATION)
            })
            .catch(error => toast.error(error))
    }

    const handleDestinationArrival = async () => {
        const url = tripRestUrl.destinationArrival.replace('{requestId}', acceptedTrip.requestId);
        HttpClient.Put(url)
            .then(response => {
                if (response === -1) {
                    setState(states.TRIP_OVER)
                } else {
                    setAcceptedTrip({ ...acceptedTrip, onGoingDestinationIdx: response });
                }
            })
            .catch(error => toast.error(error))
    }

    const handleReset = () => {
        setState(states.WAITING);
        setRoute([]);
        setRouteDetail({});
        setAcceptedTrip(null);
    }

    const openRoutingApp = () => {
        const source = `${currentPosition.lat},${currentPosition.lon}`;
        var destination;
        if (state === states.TRAVELLING_TO_ORIGIN) {
            destination = `${acceptedTrip.startPoint.lat},${acceptedTrip.startPoint.lon}`;
        } else if (state === states.TRAVELLING_TO_DESTINATION) {
            var desIdx = acceptedTrip.onGoingDestinationIdx;
            destination = `${acceptedTrip.destinations[desIdx].lat},${acceptedTrip.destinations[desIdx].lon}`;
        }

        const url = `geo:${destination}?saddr=${source}&daddr=${destination}`;
        window.location.href = url;
    };

    const getCarouselItems = () => {
        return (
            Object.values(tripRequests).map(tripRequest =>
                <div style={{ textAlign: 'center' }} key={tripRequest.requestId}>
                    <Typography variant='body1'>
                        <img src={PassengerSvg} width={20} />
                        {' '}
                        مسافر
                        :
                        <span>{tripRequest.passengerName + ` ` + tripRequest.passengerFamily}</span>
                    </Typography>
                    <Typography variant='body1'>
                        <SocialDistanceIcon />
                        {' '}
                        مسافت
                        :
                        <span>{formatDistance(tripRequest.distance)}</span>
                    </Typography>
                    <Typography variant='body1'>
                        <PaymentIcon />
                        {' '}
                        مبلغ
                        :
                        <span>{formatPrice(tripRequest.price.toString())}</span>
                    </Typography>
                    <Button color="primary" variant="contained" fullWidth onClick={() => acceptRequest(tripRequest)}>
                        <TiTick fontSize={20} />
                        قبول سفر
                    </Button>
                    <Button color="secondary" variant="contained" fullWidth onClick={() => removeRequests(tripRequest, "delete_trip_request")}>
                        <TiTimes fontSize={20} />
                        حذف درخواست
                    </Button>
                </div>
            )
        )
    }

    const getDestinationArrivalLabel = () => {
        const desNumber = acceptedTrip.onGoingDestinationIdx + 1;
        if (acceptedTrip.destinations.length === desNumber) {
            return "پایان سفر";
        }
        else {
            return `به مقصد ${desNumber} رسیدم`
        }
    }

    const getDetailField = () => {
        if (state === states.WAITING) {
            return (
                <AliceCarousel
                    disableDotsControls
                    disableButtonsControls
                    mouseTracking
                    items={getCarouselItems()}
                    onInitialized={event => {
                        setCurrentSlide(tripRequests[event.slide]);
                    }}
                    onSlideChanged={event => {
                        setCurrentSlide(tripRequests[event.slide]);
                        setPositions({
                            ...positions,
                            source: { lat: tripRequests[event.slide].startPoint.lat, lon: tripRequests[event.slide].startPoint.lon },
                            destinations: tripRequests[event.slide].destinations
                        })
                    }}
                />
            )
        }
        else if (state === states.TRAVELLING_TO_ORIGIN) {
            return (
                <Grid container style={{ justifyContent: 'space-evenly' }}>
                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                        <Typography variant='body1' className={classes.setFontSize} >
                            در مسیر مبدا
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant='body1' className={classes.setFontSize} >
                            <img src={PassengerSvg} width={20} />
                            {' '}
                            مسافر
                            :
                            <span style={{ alignSelf: 'center' }}>
                                {
                                    acceptedTrip.passengerName ?
                                        acceptedTrip.passengerName + ` ` + acceptedTrip.passengerFamily
                                        :
                                        waitingData
                                }
                            </span>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant='body1'>
                            <StayCurrentPortraitIcon />
                            {' '}
                            موبایل
                            :
                            <span>
                                {
                                    acceptedTrip.passengerMobileNumber ? convertToPersianNumber(acceptedTrip.passengerMobileNumber.replace('+98', '0'))
                                        : waitingData
                                }
                            </span>
                            <a
                                href={`tel:${acceptedTrip.passengerMobileNumber}`}
                                style={{ position: 'relative', top: 7 }}
                            >
                                <CallIcon />
                            </a>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant='body1'>
                            <SocialDistanceIcon />
                            {' '}
                            مسافت
                            :
                            <span>
                                {
                                    routeDetail.distance ? formatDistance(routeDetail.distance)
                                        : waitingData
                                }

                            </span>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant='body1'>
                            <TimelapseIcon />
                            {' '}
                            مدت زمان
                            :
                            <span>
                                {
                                    routeDetail.duration ? convertToPersianNumber(formatTime(routeDetail.duration?.toString()))
                                        : waitingData
                                }
                            </span>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant='body1'>
                            <PaymentIcon />
                            {' '}
                            مبلغ
                            :
                            <span>
                                {
                                    acceptedTrip.price ? formatPrice(acceptedTrip.price?.toString())
                                        : waitingData
                                }
                            </span>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant='body1'>
                            <HourglassTopIcon />
                            {' '}
                            توقف
                            :
                            <span>
                                {
                                    acceptedTrip.stop ? formatStop(acceptedTrip.stop)
                                        : waitingData
                                }
                            </span>
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='body1'>
                            <NoteAltIcon />
                            {' '}
                            آدرس
                            :
                            <span >
                                {
                                    routeDetail.summary ? routeDetail.summary
                                        : waitingData
                                }
                            </span>
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Button color="primary" variant="contained" fullWidth onClick={handleArriveToPassenger}>
                            به مبدا رسیدم
                        </Button>
                    </Grid>
                    <Button color="secondary" variant="contained" fullWidth onClick={handleCancelTrip}>
                        <TiTimes fontSize={20} />
                        لغو سفر
                    </Button>
                    <Button onClick={openRoutingApp} variant='contained' className={classes.navigationButton}>
                        مسیریاب <NavigationIcon />
                    </Button>
                </Grid>
            )
        }
        else if (state === states.IN_PASSENGER_PLACE) {
            return (
                <Grid container style={{ justifyContent: 'space-evenly' }}>
                    <Grid item xs={12}>
                        <Typography variant='body1' style={{ textAlign: 'center' }} className={classes.setFontSize} >
                            در انتظار مسافر
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant='body1' className={classes.setFontSize} >
                            <img src={PassengerSvg} width={20} />
                            {' '}
                            مسافر
                            :
                            <span style={{ alignSelf: 'center' }}>
                                {acceptedTrip.passengerName ? acceptedTrip.passengerName + ` ` + acceptedTrip.passengerFamily
                                    : waitingData
                                }
                            </span>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant='body1'>
                            <StayCurrentPortraitIcon />
                            {' '}
                            موبایل
                            :
                            <span>
                                {
                                    acceptedTrip.passengerMobileNumber ? convertToPersianNumber(acceptedTrip.passengerMobileNumber.replace('+98', '0'))
                                        : waitingData
                                }
                            </span>
                            <a
                                href={`tel:${acceptedTrip.passengerMobileNumber}`}
                                style={{ position: 'relative', top: 7 }}
                            >
                                <CallIcon />
                            </a>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Typography variant='body1'>
                            <PaymentIcon />
                            {' '}
                            مبلغ
                            :
                            <span>
                                {
                                    acceptedTrip.price ? formatPrice(acceptedTrip.price?.toString())
                                        : waitingData
                                }
                            </span>
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Button color="primary" variant="contained" fullWidth onClick={handlePassengerGetOn}>
                            مسافر سوار شد
                        </Button>
                    </Grid>
                    <Button color="secondary" variant="contained" fullWidth onClick={handleCancelTrip}>
                        <TiTimes fontSize={20} />
                        لغو سفر
                    </Button>
                </Grid >
            )
        }
        else if (state === states.TRAVELLING_TO_DESTINATION) {
            return (
                <Grid container style={{ justifyContent: 'space-evenly' }}>
                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                        <Typography variant='body1' className={classes.setFontSize} >
                            {`در مسیر مقصد ${acceptedTrip.destinations.length === 1 ? '' : acceptedTrip.onGoingDestinationIdx + 1}`}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant='body1' className={classes.setFontSize} >
                            <img src={PassengerSvg} width={20} />
                            {' '}
                            مسافر
                            :
                            <span style={{ alignSelf: 'center' }}>
                                {
                                    acceptedTrip.passengerName ? acceptedTrip.passengerName + ` ` + acceptedTrip.passengerFamily
                                        : waitingData
                                }
                            </span>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant='body1'>
                            <StayCurrentPortraitIcon />
                            {' '}
                            موبایل
                            :
                            <span >
                                {
                                    acceptedTrip.passengerMobileNumber ? convertToPersianNumber(acceptedTrip.passengerMobileNumber.replace('+98', '0'))
                                        : waitingData
                                }
                            </span>
                            <a
                                href={`tel:${acceptedTrip.passengerMobileNumber}`}
                                style={{ position: 'relative', top: 7 }}
                            >
                                <CallIcon />
                            </a>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant='body1'>
                            <SocialDistanceIcon />
                            {' '}
                            مسافت
                            :
                            <span style={{ alignSelf: 'center' }}>
                                {
                                    routeDetail.distance ? formatDistance(routeDetail.distance)
                                        : waitingData
                                }
                            </span>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant='body1'>
                            <TimelapseIcon />
                            {' '}
                            مدت زمان
                            :
                            <span>
                                {
                                    routeDetail.duration ? convertToPersianNumber(formatTime(routeDetail.duration?.toString()))
                                        : waitingData
                                }
                            </span>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant='body1'>
                            <PaymentIcon />
                            {' '}
                            مبلغ
                            :
                            <span>
                                {
                                    acceptedTrip.price ? formatPrice(acceptedTrip.price?.toString())
                                        : waitingData
                                }
                            </span>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant='body1'>
                            <HourglassTopIcon />
                            {' '}
                            توقف
                            :
                            <span>
                                {
                                    acceptedTrip.stop ? formatStop(acceptedTrip.stop)
                                        : waitingData
                                }
                            </span>
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='body1'>
                            <NoteAltIcon />
                            {' '}
                            آدرس
                            :
                            <span >
                                {
                                    routeDetail.summary ? routeDetail.summary
                                        : waitingData
                                }
                            </span>
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Button color="primary" variant="contained" fullWidth onClick={handleDestinationArrival}>
                            {getDestinationArrivalLabel()}
                        </Button>
                    </Grid>
                    <Button variant='contained' className={classes.navigationButton}>
                        مسیریاب <NavigationIcon />
                    </Button>
                </Grid>
            )
        }
        else if (state === states.TRIP_OVER) {
            return (

                <Grid container style={{ justifyContent: 'space-evenly' }}>
                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                        <Typography variant='body1' style={{ textAlign: 'center' }} className={classes.setFontSize} color='primary'>
                            سفر به اتمام رسید
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} >
                        <Typography variant='body1' className={classes.setFontSize} >
                            <img src={PassengerSvg} width={20} />
                            {' '}
                            مسافر
                            :
                            <span style={{ alignSelf: 'center' }}>
                                {acceptedTrip.passengerName + ` ` + acceptedTrip.passengerFamily}
                            </span>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant='body1'>
                            <StayCurrentPortraitIcon />
                            {' '}
                            موبایل
                            :
                            <span >{convertToPersianNumber(acceptedTrip.passengerMobileNumber.replace('+98', '0'))}</span>
                            <a
                                href={`tel:${acceptedTrip.passengerMobileNumber}`}
                                style={{ position: 'relative', top: 7 }}
                            >
                                <CallIcon />
                            </a>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant='body1'>
                            <PaymentIcon />
                            {' '}
                            مبلغ
                            :
                            <span> {formatPrice(acceptedTrip.price?.toString())}</span>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant='body1'>
                            <HourglassTopIcon />
                            {' '}
                            توقف
                            :
                            <span>
                                {
                                    acceptedTrip.stop ? formatStop(acceptedTrip.stop)
                                        : waitingData
                                }
                            </span>
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='body1'>
                            <PaymentsIcon />
                            {' '}
                            نوع پرداخت
                            :
                            نقدی
                        </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ padding: '6px 16px' }}>
                        <Button variant='contained' fullWidth className={classes.setFontSize} onClick={handleReset}>
                            جستجوی مسافر جدید
                        </Button>
                    </Grid>
                </Grid>
            )
        }
    }

    if (initialed) {
        return (
            <div className={classes.container}>
                <DriverMap positions={positions} defaultPosition={currentPosition} route={route} showEntireRoute={showEntireRoute} />
                <div className={classes.detailField}>
                    {getDetailField()}
                </div>
                <div className={classes.exitButton}>
                    <Tooltip title={"منوی کاربری"}>
                        <IconButton onClick={() => setMenuDialogOpen(true)} style={{ backgroundColor: 'white' }} >
                            <AccountCircleRoundedIcon fontSize='large' color={'info'} />
                        </IconButton>
                    </Tooltip>
                </div>
                <FullScreenDialog
                    open={menuDialogOpen}
                    onClose={() => setMenuDialogOpen(false)}
                >
                    <DriverMenu handleClose={() => setMenuDialogOpen(false)} />
                </FullScreenDialog>
            </div>
        )
    }
    else {
        return (<CircularProgress className={classes.circularProgress} />)
    }
}